import axios from "axios";
import { createContext, useEffect, useState } from "react";

const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [ready, setIsReady] = useState(false);

  const getUserInfo = async () => {
    try {
      const response = await axios.get("/userInfo");
      setUser(response.data.userdata);
      setIsReady(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, ready }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export { UserContextProvider };

import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import { Routes, Route } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import PractisePage from "./pages/PractisePage";
import Layout from "./sharedLayout/Layout";
import PractiseSharedLayout from "./sharedLayout/PractiseSharedLayout";
import axios from "axios";
import { UserContextProvider } from "./contexts/UserContext";
import AccountPage from "./pages/AccountPage";
import SetProblemPage from "./pages/SetProblemPage";
import TopicProblemsDisplay from "./components/TopicProblemsDisplay";
import { useEffect } from "react";
import ProblemShowCaseSharedLayout from "./sharedLayout/ProblemShowCaseSharedLayout";
import CodingPlayground from "./pages/CodingPlayground";
import SubmissionsShowCaseSharedLayout from "./sharedLayout/SubmissionsShowCaseSharedLayout";
import SubmissionsPage from "./pages/SubmissionsPage";
import EditProblemPage from "./pages/EditProblemPage";
import EditProblemLayout from "./sharedLayout/EditProblemLayout";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import CompanyWiseProblemSharedLayout from "./sharedLayout/CompanyWiseProblemSharedLayout";
import CompanyProblemsDisplay from "./components/CompanyProblemsDisplay";
import ReportProblem from "./pages/ReportProblem";
import Ide from "./pages/Ide";

axios.defaults.baseURL = "https://backend.codeverd.com";
axios.defaults.withCredentials = true;

function App() {
  const topic_data = [
    { id: "ArraysAndHashing", topic: "Arrays And Hashing" },
    { id: "Searching", topic: "Searching" },
    { id: "TwoPointers", topic: "Two Pointers" },
    { id: "LinkedLists", topic: "Linked Lists" },
    { id: "StacksAndQueues", topic: "Stacks And Queues" },
    { id: "Backtracking", topic: "Backtracking" },
    { id: "BinaryTrees", topic: "Binary Trees" },
    { id: "BSTandHeap", topic: "BST and Heap" },
    { id: "MathsAndBitManipulation", topic: "Maths And Bit Manipulation" },
    { id: "DynamicProgramming", topic: "Dynamic Programming" },
    { id: "GreedyAlgorithms", topic: "Greedy Algorithms" },
    { id: "Graphs", topic: "Graphs" },
    { id: "StringsAndTries", topic: "Strings And Tries" },
  ];

  const problems_data = [
    { id: "InsertionSort", problem: "Insertion Sort" },
    { id: "TwoSum", problem: "Two Sum" },
    { id: "SingleNumber", problem: "Single Number" },
    { id: "Intersectionoftwoarrays", problem: "Intersection of two arrays" },
    { id: "ValidAnagram", problem: "Valid Anagram" },
    { id: "MaximumSubarraySum", problem: "Maximum Subarray Sum" },
    { id: "SubarrayswithsumK", problem: "Subarrays with sum K" },
    {
      id: "Longestsubstringwithoutrepeatingcharacters",
      problem: "Longest substring without repeating characters",
    },
    { id: "LongestSubarraywithsum0", problem: "Longest Subarray with sum 0" },
    {
      id: "LongestConsecutiveSequence",
      problem: "Longest Consecutive Sequence",
    },
    { id: "DistinctAnagrams", problem: "Distinct Anagrams" },
    {
      id: "ProductofArrayexceptitself",
      problem: "Product of Array except itself",
    },
    { id: "MinimumWindowSubstring", problem: "Minimum Window Substring" },
    { id: "3Sum", problem: "3 Sum" },
  ];

  const companies = [
    "google",
    "meta",
    "amazon",
    "microsoft",
    "apple",
    "netflix",
  ];

  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="login/:error" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="ReportProblem" element={<ReportProblem />} />
          <Route path="ide" element={<Ide />} />

          <Route path="practise" element={<PractiseSharedLayout />}>
            <Route index element={<PractisePage />} />
            {topic_data.map((topic, index) => {
              return (
                <Route
                  path={topic.id}
                  element={
                    <TopicProblemsDisplay
                      topicId={topic.id}
                      topic={topic.topic}
                    />
                  }
                />
              );
            })}
          </Route>
          <Route path="/company" element={<CompanyWiseProblemSharedLayout />}>
            {companies.map((company) => {
              return (
                <Route
                  path={company}
                  element={<CompanyProblemsDisplay company={company} />}
                />
              );
            })}
          </Route>
          <Route path="problem" element={<ProblemShowCaseSharedLayout />}>
            {problems_data.map((problem, index) => {
              return (
                <Route
                  path={problem.id}
                  element={<CodingPlayground problemId={problem.id} />}
                />
              );
            })}
          </Route>
          <Route
            path="submissions"
            element={<SubmissionsShowCaseSharedLayout />}
          >
            {problems_data.map((problem, index) => {
              return (
                <Route
                  path={problem.id}
                  element={<SubmissionsPage problemId={problem.id} />}
                />
              );
            })}
          </Route>

          <Route path="account" element={<AccountPage />} />
          <Route path="setproblem" element={<SetProblemPage />} />
          <Route path="editproblem" element={<EditProblemLayout />}>
            {problems_data.map((problem) => {
              return (
                <Route
                  path={problem.id}
                  element={<EditProblemPage problemId={problem.id} />}
                />
              );
            })}
          </Route>
          <Route path="ResetPassword" element={<ResetPassword />}></Route>
          <Route path="/reset/:token" element={<ChangePassword />} />
          <Route
            path="updatePasswordFromAccount"
            element={<ChangePassword />}
          />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;

import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="max-w-[1240px] mx-auto py-4 px-4 flex justify-between text-gray-300 h-[50px] my-4">
      <Link to="/ReportProblem" className=" hover:text-sky-500">
        Report a Problem
      </Link>
      <div>admin@codeverd.com</div>

      <ul className="block sm:flex gap-5">
        <li className="p-1">
          <a href="https://www.facebook.com/codeverd" target="_blank">
            <FaFacebookSquare size={20} />
          </a>
        </li>
        <li className="p-1">
          <a href="https://instagram.com/codeverd" target="_blank">
            <FaInstagram size={20} />
          </a>
        </li>
        <li className="p-1">
          <a href="https://www.twitter.com/codeverd" target="_blank">
            <FaTwitterSquare size={20} />
          </a>
        </li>
        <li className="p-1">
          <a href="https://www.youtube.com/@CodeVerd" target="_blank">
            <FaYoutubeSquare size={20} />
          </a>
        </li>
      </ul>
    </div>
  );
}
export default Footer;

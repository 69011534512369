import community from "../Assets/images/community.jpg";

function CommunityShowcase() {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1024px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className=" text-sky-500 font-bold md:text-4xl sm:text-3xl text-2xl py-2">
            A great community
          </h1>
          <p className="md:text-3xl sm:text-2xl text-xl">
            An Online community where you can connect with like minded people
            and grow together.
          </p>
        </div>
        <img src={community} className="w-[500px] mx-auto my-4" alt="/" />
      </div>
    </div>
  );
}
export default CommunityShowcase;

import { useState } from "react";
import LoginRegisterError from "../components/LoginRegisterError";
import LoginRegisterSuccess from "../components/LoginRegisterSuccess";
import { Link } from "react-router-dom";
import axios from "axios";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, SetIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showForm, setShowForm] = useState(true);

  const sendPasswordResetRequest = async (e, email) => {
    e.preventDefault();

    setIsError(false);
    SetIsSuccess(false);

    const result = await axios.post("/resetpassword", { email: email });
    const data = result.data;

    if (data.success == false) {
      setIsError(true);
      setErrorMsg(data.msg);
    } else {
      SetIsSuccess(true);
      setSuccessMsg(data.msg);
      setShowForm(false);
    }
  };

  return (
    <div className=" bg-white  h-screen ">
      <div className="h-4/5 flex justify-center items-center">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-4 border my-auto"
          onSubmit={(e) => sendPasswordResetRequest(e, email)}
        >
          {isError && <LoginRegisterError error={errorMsg} />}
          {isSuccess && <LoginRegisterSuccess message={successMsg} />}
          {showForm && (
            <>
              <h2 className="text-xl font-bold text-center py-6">
                Reset Password
              </h2>
              <div className="flex flex-col py-2">
                <input
                  className="border p-2"
                  type="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <button
                className="border w-full my-5 py-2 bg-sky-600 text-white"
                type="submit"
              >
                Send Password Reset Link
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
export default ResetPassword;

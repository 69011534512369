import practise from "../Assets/images/practise.jpg";

function ProblemsShowcase() {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1024px] mx-auto grid md:grid-cols-2">
        <img src={practise} className="w-[500px] mx-auto my-4" alt="/" />
        <div className="flex flex-col justify-center">
          <h1 className=" text-sky-500 font-bold md:text-4xl sm:text-3xl text-2xl py-2">
            Practise! Practise! Practise!
          </h1>
          <p className="md:text-3xl sm:text-2xl text-xl">
            Curated problems to get you prepared for interviews for top tech
            companies.
          </p>
        </div>
      </div>
    </div>
  );
}
export default ProblemsShowcase;

import CodeMirror from "@uiw/react-codemirror";
import { eclipse, eclipseInit } from "@uiw/codemirror-theme-eclipse";
import { langs } from "@uiw/codemirror-extensions-langs";
import RingLoader from "react-spinners/RingLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import HashLoader from "react-spinners/HashLoader";
import { CSSProperties } from "react";
import { useState } from "react";
import axios from "axios";

function Ide() {
  const [code, setCode] = useState("");

  const runIdeCode = async () => {
    const body = { code: code, lang: "cpp" };

    try {
      const result = await axios.post("/runIdeCode", body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" bg-white w-full">
      <div className="max-w-[1280px] mx-auto ">
        <div className="text-center text-5xl">IDE</div>
        <div className="mx-4 my-2 p-2 rounded-lg border border-gray-300 mt-2">
          <CodeMirror
            value={code}
            height="600px"
            theme={eclipseInit({
              settings: {
                caret: "#000",
                fontFamily: "monospace",
              },
            })}
            extensions={langs.cpp()}
            onChange={(value, change) => {
              setCode(value);
            }}
          />
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="p-2 bg-green-600 hover:bg-green-500 text-white px-6 mt-2 mx-4 mb-2 rounded-lg font-mono"
            onClick={runIdeCode}
          >
            Run
          </button>
        </div>
      </div>
    </div>
  );
}
export default Ide;

import { Link } from "react-router-dom";
import { FcVideoFile } from "react-icons/fc";
function EditorialComponent({ link }) {
  return (
    <div className="flex justify-center items-center">
      <FcVideoFile size={25} />
      <a href={link} className="underline" target="_blank">
        Editorial
      </a>
    </div>
  );
}
export default EditorialComponent;

function SetProblemSuccess({ error }) {
  return (
    <div>
      {" "}
      <div
        className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Awesome! </strong>
        <span className="block sm:inline">{error}</span>
      </div>
    </div>
  );
}
export default SetProblemSuccess;

import signup from "../Assets/images/signup.jpg";
import LoginRegisterError from "../components/LoginRegisterError";
import LoginRegisterSuccess from "../components/LoginRegisterSuccess";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useEffect } from "react";

function RegisterPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [navigate, setNavigate] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const registerUser = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post("/register", {
        name,
        email,
        password,
      });

      if (data.success == false) {
        setIsError(true);
        setErrorMsg(data.error);
      } else {
        setIsError(false);
        setSuccessMsg("You are registered");
        setUser(data.userInfo);
        setIsSuccess(true);
        setNavigate(true);
      }
    } catch (e) {
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  if (!!user || navigate) {
    return <Navigate to="/account" />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 bg-white h-screen w-full">
      <div className="hidden md:block">
        <img className="w-full h-screen object-cover" src={signup} alt="/" />
      </div>

      <div className="flex flex-col justify-center">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-4 border"
          onSubmit={registerUser}
        >
          {isError && <LoginRegisterError error={errorMsg} />}
          {isSuccess && <LoginRegisterSuccess message={successMsg} />}
          <h2 className="text-4xl font-bold text-center py-6">Register</h2>
          <div className="flex flex-col py-2">
            <input
              className="border p-2"
              type="text"
              placeholder="Name"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col py-2">
            <input
              className="border p-2"
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col py-2">
            <input
              className="border p-2"
              type="password"
              placeholder="Password"
              value={password}
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <button
            className="border w-full my-5 py-2 bg-sky-600 text-white"
            type="submit"
          >
            Sign Up
          </button>
          <div className="flex justify-center">
            <p className="text-gray-500">
              Have an account already?
              <Link className="underline text-black" to="/login">
                Login
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
export default RegisterPage;

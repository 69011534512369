import microsoftlogo from "../Assets/compaanylogos/microsoft.svg";
import googlelogo from "../Assets/compaanylogos/google.png";
import amazonlogo from "../Assets/compaanylogos/amazon.svg";
import flipkartlogo from "../Assets/compaanylogos/flipkart.svg";
import applelogo from "../Assets/compaanylogos/apple.png";
import metalogo from "../Assets/compaanylogos/meta.png";
import netflixlogo from "../Assets/compaanylogos/netflix.png";

function CompanyLogo({ company }) {
  if (company == "netflix")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={netflixlogo} width="25px" />}
      </div>
    );

  if (company == "microsoft")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={microsoftlogo} width="25px" />}
      </div>
    );

  if (company == "facebook" || company == "meta")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={metalogo} width="25px" />}
      </div>
    );

  if (company == "apple")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={applelogo} width="25px" />}
      </div>
    );
  if (company == "google")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={googlelogo} width="25px" />}
      </div>
    );
  if (company == "amazon")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={amazonlogo} width="25px" />}
      </div>
    );
  if (company == "flipkart")
    return (
      <div className="w-[25px] h-[25px]">
        {<img src={flipkartlogo} width="25px" />}
      </div>
    );
}
export default CompanyLogo;

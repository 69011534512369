import { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import axios from "axios";
import GaugeChart from "react-gauge-chart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiFillStar } from "react-icons/ai";
import HashLoader from "react-spinners/HashLoader";
import { BiMessageAltError } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function AccountPage() {
  const { user, ready, setUser } = useContext(UserContext);
  const [userRating, setUserRating] = useState("");
  const [userAccuracy, setUserAccuracy] = useState(0);
  const [totalProblemsSolved, setTotalProblemsSolved] = useState(0);
  const [totalProblems, setTotalProblems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, SetIsError] = useState(false);
  const navigate = useNavigate();

  const getAccountDetails = async () => {
    try {
      const response = await axios.get("/accountDetails");
      if (response.data.success == true) {
        setTotalProblemsSolved(response.data.userData.totalProblemsUserSolved);
        setUserAccuracy(response.data.userData.userAccuracy);
        setTotalProblems(response.data.userData.totalProblems);
        setIsLoading(false);
      } else {
        SetIsError(true);
        setIsLoading(false);
      }
    } catch (err) {
      SetIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!user) {
      getAccountDetails();
    }
  }, [user]);

  if (ready && !user) {
    return <Navigate to="/login" />;
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <BiMessageAltError size={40} color="red" />
        <div>Some error occurred.Please reload this page.</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <HashLoader />
      </div>
    );
  }

  const handleLogout = async () => {
    setUser(null);
    setIsLoading(true);
    try {
      const response = await axios.get("/logout");
      if (response.data.success == true) {
        window.localStorage.clear();
        setIsLoading(false);
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full bg-gray-100 py-8 px-4 font-mono  flex items-center justify-center">
      <div className="max-w-[1280px] mx-auto  my-auto">
        <div className="flex justify-end p-4">
          <button
            onClick={handleLogout}
            className="p-1 ml-4 rounded text-sky-600 font-sans"
          >
            Logout
          </button>
          <Link
            to="/updatePasswordFromAccount"
            className="p-1 ml-4 rounded text-sky-600 font-sans"
          >
            Change the Password
          </Link>
        </div>
        <div className=" font-sans text-4xl text-center capitalize">
          Hey, {user.name}
        </div>
        <div className="lg:flex  gap-4 px-4 pb-20 pt-20 justify-center items-center sm:block">
          <div className=" h-64 flex flex-col justify-center  gap-3 items-center w-[300px] bg-white rounded-xl mx-auto mb-4 lg:m-0">
            <div className="mt-2"> Problems Solved</div>
            <div className=" bg-white p-1 rounded-full  text-lg 	w-[200px] h-[200px] ">
              <CircularProgressbar
                value={(totalProblemsSolved / totalProblems) * 100}
                text={`${totalProblemsSolved}/${totalProblems}`}
                circleRatio={0.75}
                strokeWidth={4}
                styles={buildStyles({
                  rotation: 1 / 2 + 1 / 8,
                  strokeLinecap: "butt",
                  trailColor: "#eee",
                  textSize: "12px",
                })}
              />
            </div>
          </div>
          <div className=" lg:h-72 lg:w-[350px] h-64 flex flex-col justify-center gap-3 items-center w-[300px] bg-white rounded-xl mx-auto mb-4 lg:m-0">
            {totalProblemsSolved == 0 && (
              <>
                <div className="text-gray-600 font-bold">No rating yet</div>
              </>
            )}

            {totalProblemsSolved > 0 && (
              <>
                <div className="text-green-600">CodeVerd Beginner</div>
                <div className="flex justify-center items-center text-green-600">
                  <div className=" text-5xl">1</div>
                  <AiFillStar size={50} />
                </div>
              </>
            )}

            {totalProblemsSolved > 30 && (
              <>
                <div className="text-blue-600">CodeVerd Novice</div>
                <div className="flex justify-center items-center text-blue-600">
                  <div className=" text-5xl">2</div>
                  <AiFillStar size={50} />
                </div>
              </>
            )}

            {totalProblemsSolved > 70 && (
              <>
                <div className="text-yellow-600">CodeVerd Expert</div>
                <div className="flex justify-center items-center text-yellow-600">
                  <div className=" text-5xl">3</div>
                  <AiFillStar size={50} />
                </div>
              </>
            )}

            {totalProblemsSolved > 100 && (
              <>
                <div className="text-purple-600">CodeVerd Master</div>
                <div className="flex justify-center items-center text-purple-600">
                  <div className=" text-5xl">4</div>
                  <AiFillStar size={50} />
                </div>
              </>
            )}

            {totalProblemsSolved > 130 && (
              <>
                <div className="text-red-600">CodeVerd Pro</div>
                <div className="flex justify-center items-center text-red-600">
                  <div className=" text-5xl">5</div>
                  <AiFillStar size={50} />
                </div>
              </>
            )}
          </div>
          <div className="  h-64 flex justify-center flex-col gap-3 items-center w-[300px] bg-white rounded-xl mx-auto mb-4 lg:m-0 font-light">
            <div className="mt-2  font-light"> Accuracy</div>
            <GaugeChart
              id="gauge-chart4"
              nrOfLevels={26}
              arcPadding={0.005}
              cornerRadius={2}
              colors={["#5BE12C", "#F5CD19", "#EA4228"]}
              hideText={false}
              textColor="black"
              percent={userAccuracy / 100}
              needleColor="#D3D3D3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default AccountPage;

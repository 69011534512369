import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ProblemsShowcase from "../components/ProblemsShowcase";
import CommunityShowcase from "../components/CommunityShowcase";
import VideoSolutionsShowcase from "../components/VideoSolutionsShowcase";
import Footer from "../components/Footer";

function IndexPage() {
  return (
    <div>
      <Hero />
      <ProblemsShowcase />
      <CommunityShowcase />
      <VideoSolutionsShowcase />
    </div>
  );
}
export default IndexPage;

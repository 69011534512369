import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "./CompanyLogo";
import { FcCheckmark } from "react-icons/fc";

function CompanyProblemsDisplay({ company }) {
  const [problemsData, SetProblemsData] = useState([]);
  const [userSolvedProblems, SetUserSolvedProblems] = useState([]);

  const getTopicProblems = async () => {
    try {
      const res = await axios.get(`/api/problems/${topicId}`);
      SetProblemsData(res.data.problems);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserSolvedProblems = async () => {
    try {
      const res = await axios.get(`api/usersolvedproblems/`);
      SetUserSolvedProblems(res.data.usersolvedproblems);
      console.log(res.data.usersolvedproblems);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTopicProblems();
    getUserSolvedProblems();
  }, []);

  return (
    <div>
      <div className="p-5 bg-gray-100">
        <h1 className="text-4xl mb-2 text-center">{topic}</h1>

        <div className="overflow-auto rounded-lg shadow  md:block max-w-[1024px] m-auto">
          <table className="w-full text-center">
            <thead className="bg-gray-50 border-b-2 border-gray-200 text-center">
              <tr>
                <th className="p-3 text-md font-semibold tracking-wide ">
                  Problem
                </th>
                <th className=" p-3 text-md font-semibold tracking-wide ">
                  Difficulty
                </th>
                <th className=" p-3 text-md font-semibold tracking-wide ">
                  Companies
                </th>
              </tr>
            </thead>
            {problemsData.map((problem) => {
              return (
                <>
                  <tbody className="divide-y divide-gray-100 h-[80px]">
                    <tr className="bg-white">
                      <td className="p-3 text-md text-gray-700 whitespace-nowrap">
                        <div className="flex gap-2  items-center justify-center">
                          <Link to={`/problem/${problem.problemId}`}>
                            {problem.problemTitle}
                          </Link>
                          {userSolvedProblems?.includes(problem.problemId) && (
                            <FcCheckmark color="green" size={25} />
                          )}
                        </div>
                      </td>
                      <td className="p-3 text-md text-gray-700 whitespace-nowrap text-center">
                        {problem.difficulty == "easy" && (
                          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                            {problem.difficulty}
                          </span>
                        )}
                        {problem.difficulty == "medium" && (
                          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                            {problem.difficulty}
                          </span>
                        )}
                        {problem.difficulty == "hard" && (
                          <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                            {problem.difficulty}
                          </span>
                        )}
                      </td>
                      <td className="p-3 text-md text-gray-700 whitespace-nowrap">
                        <div className="flex gap-2  items-center justify-center">
                          {problem.companyTags.map((company) => {
                            return <CompanyLogo company={company} />;
                          })}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}
export default CompanyProblemsDisplay;

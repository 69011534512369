import { useState } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

function PaginationComponent({
  submissionsPerPage,
  totalSubmissions,
  paginate,
  currentPage,
  fetchReady,
  isError,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalSubmissions / submissionsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (isError) {
    return (
      <div className="p-4 flex justify-center font-semibold text-xl">
        Some Error Occured,Please try again.
      </div>
    );
  }

  if (totalSubmissions == 0 && fetchReady) {
    return (
      <div className="p-4 flex justify-center font-semibold text-xl">
        No submissions to show
      </div>
    );
  }

  return (
    <nav className="mx-auto mt-5">
      <ul className="flex justify-center gap-1">
        {fetchReady && (
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={`${currentPage == 1 ? "disabled" : ""}`}
            className={`${currentPage == 1 ? " cursor-not-allowed" : ""}`}
          >
            <li className="p-3  font-bold">
              <div className=" font-bold h-[25px] w-[25px] text-center flex justify-center items-center">
                <MdOutlineArrowBackIosNew />
              </div>
            </li>
          </button>
        )}
        {pageNumbers.map((number) => {
          return (
            <button>
              <li
                key={number}
                className={`p-3   font-bold ${
                  number == currentPage
                    ? "bg-slate-500 text-white rounded-full"
                    : "bg-white text-black"
                }`}
                onClick={() => paginate(number)}
              >
                <div className=" font-light text-sm h-[4px] w-[4px] text-center flex justify-center items-center ">
                  {number}
                </div>
              </li>
            </button>
          );
        })}
        {fetchReady && (
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={`${currentPage == pageNumbers.length ? "disabled" : ""}`}
            className={`${
              currentPage == pageNumbers.length ? " cursor-not-allowed" : ""
            }`}
          >
            <li className="p-3  font-bold">
              <div className=" font-bold h-[25px] w-[25px] text-center flex justify-center items-center">
                <MdOutlineArrowForwardIos />
              </div>
            </li>
          </button>
        )}
      </ul>
    </nav>
  );
}
export default PaginationComponent;

import Logo from "./Logo";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [windowSize, SetWindowSize] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    const updateWindowSize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 768) {
        SetWindowSize("medium");
        setNav(false);
      } else {
        SetWindowSize("small");
      }
    };

    window.addEventListener("resize", updateWindowSize);

    updateWindowSize();

    return () => window.removeEventListener("resize", updateWindowSize);
  }, [nav]);

  const handleNav = () => {
    return setNav(!nav);
  };

  return (
    <div className="text-white flex justify-between h-24 items-center max-w-[1024px] mx-auto px-4">
      <Link to="/">
        <div className="flex items-center">
          <Logo />
          <h1 className="w-full text-3xl font-bold text-white">CodeVerd</h1>
        </div>
      </Link>
      <ul className="hidden md:flex md:items-center ">
        <li className="p-4">
          <Link to="/" className=" hover:text-sky-500 ">
            Home
          </Link>
        </li>
        <li className="p-4">
          <Link to="/practise" className=" hover:text-sky-500">
            Practise
          </Link>
        </li>
        <li className="p-2">
          {!!user ? (
            <Link to="/account" className=" bg-sky-600 p-2 ml-4 rounded">
              Profile
            </Link>
          ) : (
            <Link to="/login" className=" bg-sky-600 p-2 ml-4 rounded">
              Login
            </Link>
          )}
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-gray-900 ease-in-out duration-300 bg-black z-20"
            : "fixed left-[-100%]"
        }
      >
        <ul className="pt-24">
          <Link to="/">
            <li className="p-4 border-b border-gray-600 ">Home</li>
          </Link>
          <Link to="/practise">
            <li className="p-4 border-b border-gray-600 ">Practise</li>
          </Link>
          <li className="py-4 border-b border-gray-600 ">
            {!!user ? (
              <Link to="/account" className=" bg-sky-600 p-2 ml-4 rounded">
                Profile
              </Link>
            ) : (
              <Link to="/login" className=" bg-sky-600 p-2 ml-4 rounded">
                Login
              </Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Navbar;

import { Outlet } from "react-router-dom";

function CompanyWiseProblemSharedLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
export default CompanyWiseProblemSharedLayout;

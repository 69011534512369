import VideoSolutions from "../Assets/images/VideoSolutions.jpg";

function VideoSolutionsShowcase() {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1024px] mx-auto grid md:grid-cols-2">
        <img src={VideoSolutions} className="w-[500px] mx-auto my-4" alt="/" />
        <div className="flex flex-col justify-center">
          <h1 className=" text-sky-500 font-bold md:text-4xl sm:text-3xl text-2xl py-2">
            No more text editorials
          </h1>
          <p className="md:text-3xl sm:text-2xl text-xl">
            High quality video editorials so you can grasp the concepts better,
            all for <span className="font-bold">FREE.</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default VideoSolutionsShowcase;

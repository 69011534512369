import Typed from "react-typed";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="text-white">
      <div className="max-w-[800px] h-screen mx-auto text-center flex flex-col justify-center items-center">
        <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold md:py-6">
          Coding Interviews Made Easy
        </h1>
        <div className="flex justify-center">
          <p className="text-xl sm:text-4xl md:text-5xl font-bold">
            Let's crack
          </p>
          <Typed
            className="text-xl sm:text-4xl md:text-5xl font-bold pl-2 text-sky-500"
            strings={["Google", "Meta", "Amazon", "Apple", "Microsoft"]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <Link
          to="Login"
          className=" bg-sky-600 rounded w-[200px] py-4 my-6 font-bold"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
}
export default Hero;

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import CodeModal from "../components/CodeModal";
import { eclipse, eclipseInit } from "@uiw/codemirror-theme-eclipse";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { StreamLanguage } from "@codemirror/language";
import { langs } from "@uiw/codemirror-extensions-langs";
import { ImCross } from "react-icons/im";
import PaginationComponent from "../components/PaginationComponent";
import MoonLoader from "react-spinners/MoonLoader";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function SubmissionsPage({ problemId }) {
  const [submissionsData, SetSubmissionsData] = useState([]);
  const [showCodeModal, SetShowCodeModal] = useState(false);
  const [modalCode, setModalCode] = useState("//Fetching your submission....");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionsPerPage] = useState(5);

  //liading
  const [isLoading, SetIsLoading] = useState(true);

  //error
  const [IsError, SetIsError] = useState(false);

  //usercontext
  const { ready, user } = useContext(UserContext);

  //submissionsFetchReady
  const [fetchReady, SetFetchReady] = useState(false);

  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissionsData.slice(
    indexOfFirstSubmission,
    indexOfLastSubmission
  );

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  function getTimeDifference(createdAt) {
    const creationTime = new Date(createdAt);
    const now = new Date();
    const diffInSeconds = Math.floor((now - creationTime) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} second(s) ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} minute(s) ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours} hour(s) ago`;
    } else if (diffInSeconds < 604800) {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${diffInDays} day(s) ago`;
    } else if (diffInSeconds < 2592000) {
      const diffInWeeks = Math.floor(diffInSeconds / 604800);
      return `${diffInWeeks} week(s) ago`;
    } else if (diffInSeconds < 31536000) {
      const diffInMonths = Math.floor(diffInSeconds / 2592000);
      return `${diffInMonths} month(s) ago`;
    } else {
      const diffInYears = Math.floor(diffInSeconds / 31536000);
      return `${diffInYears} year(s) ago`;
    }
  }

  const getSubmissions = async () => {
    try {
      const submissions = await axios.get(`/submissions/${problemId}`);

      if (submissions.data.success == false) {
        SetIsError(true);
        SetIsLoading(false);
        SetSubmissionsData([]);
        SetFetchReady(true);
      } else {
        SetIsError(false);
        SetIsLoading(false);
        SetSubmissionsData(submissions.data.result);
        SetFetchReady(true);
      }
    } catch (e) {
      SetIsLoading(false);
      SetIsError(true);
    }
  };

  useEffect(() => {
    getSubmissions();
  }, []);

  const getCode = async (submissionId) => {
    try {
      const result = await axios.get(`/api/getsubmission/${submissionId}`);

      if (result.data.success == true) {
        setModalCode(result.data.result);
      } else {
        setModalCode("Some error occurred,please try again.");
      }
    } catch (e) {
      setModalCode("Some error occurred,please try again.");
    }
  };

  const showCode = (submissionId) => {
    getCode(submissionId);
    SetShowCodeModal(!showCodeModal);
  };

  const handleCloseModal = () => {
    setModalCode("//Fetching your submission....");
    SetShowCodeModal(!showCodeModal);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (ready && !user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="relative">
      <div className="p-5 h-screen  bg-white">
        <h1 className="text-3xl m-2 text-center font-light">
          Your Submissions
        </h1>

        {showCodeModal && (
          <>
            <div className="fixed inset-0 backdrop-blur bg-gray-900 bg-opacity-50">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <CodeModal
                  code={modalCode}
                  SetShowCodeModal={SetShowCodeModal}
                  showCodeModal={showCodeModal}
                  handleCloseModal={handleCloseModal}
                  setModalCode={setModalCode}
                />
              </div>
            </div>
          </>
        )}

        <div className="overflow-auto  shadow  md:block max-w-[1200px] mx-auto mt-8 font-mono ">
          <table className="w-full">
            <thead className=" bg-gray-600 border-b-2 border-gray text-white ">
              <tr>
                <th className="p-3  text-md font-semibold tracking-wide text-center w-1/3 rounded-tl-md">
                  Submission ID
                </th>
                <th className="p-3 text-md font-semibold tracking-wide text-center w-1/3">
                  Status
                </th>
                <th className="p-3 text-md font-semibold tracking-wide text-center w-1/3 rounded-tr-md">
                  Time
                </th>
              </tr>
            </thead>

            {!isLoading &&
              currentSubmissions.map((submission) => {
                return (
                  <>
                    <tbody className="divide-y divide-gray-100 h-[80px]">
                      <tr className=" bg-white-300 ">
                        <td className="p-3 text-md text-gray-700 whitespace-nowrap text-center border">
                          <div className=" text-blue-500 underline">
                            <button
                              onClick={() => showCode(submission.submissionId)}
                            >
                              {submission.submissionId}
                            </button>
                          </div>
                        </td>

                        <td className="p-3 text-md text-gray-700 whitespace-nowrap text-center border">
                          {submission.result.includes("failed") && (
                            <div className=" text-red-400 font-bold">
                              Failed
                            </div>
                          )}
                          {submission.result == "passed" && (
                            <div className=" text-green-600 font-bold">
                              Passed
                            </div>
                          )}
                          {submission.result == "compilation_error" && (
                            <div className=" text-black-600 font-bold">
                              Compilation Error
                            </div>
                          )}
                          {submission.result == "segmentation_fault" && (
                            <div className=" text-sky-400 font-bold">
                              Segmentation Fault
                            </div>
                          )}
                          {submission.result == "timeout" && (
                            <div className=" text-yellow-500 font-bold">
                              Time Limit Exceeded
                            </div>
                          )}
                        </td>
                        <td className="p-3 text-md text-gray-700 whitespace-nowrap text-center border">
                          {getTimeDifference(submission.createdAt)}
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
          </table>
          {isLoading && (
            <div className="p-4">
              <MoonLoader
                loading={isLoading}
                cssOverride={override}
                size={50}
                color="black"
              />
            </div>
          )}
        </div>
        <PaginationComponent
          submissionsPerPage={submissionsPerPage}
          paginate={paginate}
          totalSubmissions={submissionsData.length}
          currentPage={currentPage}
          fetchReady={fetchReady}
          isError={IsError}
        />
      </div>
    </div>
  );
}
export default SubmissionsPage;

import { useState } from "react";
import LoginRegisterError from "../components/LoginRegisterError";
import LoginRegisterSuccess from "../components/LoginRegisterSuccess";
import { useParams } from "react-router-dom";
import axios from "axios";

function ChangePassword() {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, SetIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRetype, setNewPasswordRetype] = useState("");
  const { token } = useParams();

  const updatePassword = async (e) => {
    e.preventDefault();

    setIsError(false);
    SetIsSuccess(false);

    try {
      if (newPassword != newPasswordRetype) {
        setIsError(true);
        setErrorMsg("Passwords don't match");
      } else if (!!!token) {
        const result = await axios.post("/updatePasswordFromAccount", {
          newPassword,
        });
        const data = result.data;
        if (data.success == false) {
          setIsError(true);
          setErrorMsg(data.msg);
        } else {
          SetIsSuccess(true);
          setSuccessMsg(data.msg);
          setShowForm(false);
        }
      } else {
        const result = await axios.post("/updatePassword", {
          newPassword,
          token,
        });
        const data = result.data;
        if (data.success == false) {
          setIsError(true);
          setErrorMsg(data.msg);
        } else {
          SetIsSuccess(true);
          setSuccessMsg(data.msg);
          setShowForm(false);
        }
      }
    } catch (e) {
      setIsError(true);
      setErrorMsg("Some error occurred, please try again.");
    }
  };

  return (
    <div className="bg-white h-screen">
      <div className=" h-4/5 flex flex-col justify-center">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-4 border"
          onSubmit={updatePassword}
        >
          {isError && <LoginRegisterError error={errorMsg} />}
          {isSuccess && <LoginRegisterSuccess message={successMsg} />}
          {showForm && (
            <>
              {" "}
              <h2 className="text-xl font-bold text-center py-6">
                Change Password
              </h2>
              <div className="flex flex-col py-2">
                <input
                  className="border p-2"
                  type="password"
                  placeholder="New Password"
                  required
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col py-2">
                <input
                  className="border p-2"
                  type="password"
                  placeholder="Re-type New Password"
                  required
                  value={newPasswordRetype}
                  onChange={(e) => {
                    setNewPasswordRetype(e.target.value);
                  }}
                />
              </div>
              <button
                className="border w-full my-5 py-2 bg-sky-600 text-white"
                type="submit"
              >
                Update Password
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
export default ChangePassword;

import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { StreamLanguage } from "@codemirror/language";
import { langs } from "@uiw/codemirror-extensions-langs";
import { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import RingLoader from "react-spinners/RingLoader";
import { eclipse, eclipseInit } from "@uiw/codemirror-theme-eclipse";

function CodeModal({
  code,
  SetShowCodeModal,
  showCodeModal,
  handleCloseModal,
  setModalCode,
}) {
  const [editorHeight, SetEditorHeight] = useState("500px");
  const [editorWidth, SetEditorWidth] = useState("900px");

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    SetEditorHeight(`${windowHeight - 200}px`);
    SetEditorWidth(`${windowWidth - 200}px`);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div>
      <div className="flex justify-end p-2 bg-white">
        <button onClick={handleCloseModal}>
          <ImCross color="black" />
        </button>
      </div>
      <div className="">
        <CodeMirror
          value={code}
          width={editorWidth}
          height={editorHeight}
          theme={eclipse}
          extensions={langs.cpp()}
          onChange={(value, change) => {
            setModalCode(code + " ");
          }}
        />
      </div>
    </div>
  );
}
export default CodeModal;

import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark, vscodeDarkInit } from "@uiw/codemirror-theme-vscode";
import { defaultSettingsEclipse } from "@uiw/codemirror-theme-eclipse";
import { eclipse, eclipseInit } from "@uiw/codemirror-theme-eclipse";
import { StreamLanguage } from "@codemirror/language";
import { langs } from "@uiw/codemirror-extensions-langs";
import RingLoader from "react-spinners/RingLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import HashLoader from "react-spinners/HashLoader";
import { BsXCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { AiFillQuestionCircle } from "react-icons/ai";
import { CSSProperties } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FcOrgUnit } from "react-icons/fc";
import { BiTime } from "react-icons/bi";
import { BiError } from "react-icons/bi";
import { BiMessageAltError } from "react-icons/bi";
import {
  githubLight,
  githubLightInit,
  githubDark,
  githubDarkInit,
} from "@uiw/codemirror-theme-github";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import DifficultyComponent from "../components/DifficultyComponent";
import EditorialComponent from "../components/EditorialComponent";
import { Link, useNavigate } from "react-router-dom";
import { BiHistory } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { UserContext } from "../contexts/UserContext";

import FullLoadComponent from "../components/FullLoadComponent";
import { Code } from "react-content-loader";

import { io, Socket } from "socket.io-client";

function CodingPlayground({ problemId }) {
  const [problemsData, SetProblemsData] = useState([]);
  const [showExpectedOutput, setShowExpectedOutput] = useState(false);
  const [showYourOutput, setShowYourOutput] = useState(false);
  const [ExpectedOutput, SetExpectedOutput] = useState("");
  const [YourOutput, SetYourOutput] = useState("");
  const [showTestCaseInput, SetShowTestCaseInput] = useState(false);
  const [TestCaseInput, SetTestCaseInput] = useState("");
  const [isReady, SetIsReady] = useState(false);
  const { user, ready } = useContext(UserContext);
  const [customInput, SetCustomInput] = useState("");
  const [customOutput, SetCustomOutput] = useState("");
  const [showCustomOutput, SetShowCustomOutput] = useState(false);
  const [socketId, setSocketId] = useState(null);
  const [socket, setSocket] = useState(null);
  const runCompleteListenerAttached = useRef(0);
  const submitCompleteListenerAttached = useRef(0);
  const [isAccepted, SetIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRunningTests, setShowRunningTests] = useState(false);
  const [showIsAcceptedText, SetShowIsAcceptedText] = useState(false);
  const [isFailed, SetIsFailed] = useState(false);
  const [showIsFailedText, SetShowIsFailedText] = useState(false);
  const [isSegmentationFault, SetIsSegmentationFault] = useState(false);
  const [isTimeout, SetIsTimeout] = useState(false);
  const [ShowTimeoutText, SetShowTimeoutText] = useState(false);
  const [isRuntimeError, SetIsRuntimeError] = useState(false);
  const [showRuntimeErrorText, SetShowRuntimeErrorText] = useState(false);
  const [isCompilationError, SetIsCompilationError] = useState(false);
  const [isCustomInputChecked, SetIsCustomInputChecked] = useState(false);
  const [showCompilationError, SetShowCompilationError] = useState(false);
  const [showTestCase, SetShowTestCase] = useState(false);
  const [isError, setIsError] = useState(false);

  const [showCompilationErrorText, SetShowCompilationErrorTest] =
    useState(false);
  const [showIsSegmentationFaultText, SetShowIsSegmentationFaultText] =
    useState(false);
  const [compilationError, SetCompilationError] = useState("");
  const [showCompilationSuccessText, SetShowCompilationSuccessText] =
    useState(false);
  const [isSubmitRunError, SetIsSubmitRunError] = useState(false);

  const userInputRef = useRef(null);
  const rightPaneRef = useRef(null);
  const compilationErrorRef = useRef(null);
  const navigate = useNavigate();
  const [code, setCode] = useState(`#include<iostream>
using namespace std;

int main()
{ 

//Please write your cpp code here

return 0;
}`);

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const getProblemData = async () => {
    try {
      const res = await axios.get(`/api/problem/${problemId}`);

      if (res.data.success == true) {
        if (
          window.localStorage.getItem(
            `code-${res.data.problemData[0].problemId}`
          )
        ) {
          setCode(
            window.localStorage.getItem(
              `code-${res.data.problemData[0].problemId}`
            )
          );
        }
        SetProblemsData(res.data.problemData[0]);
        SetIsReady(true);
      } else if (res.data.success == false) {
        SetIsReady(false);
        setIsError(true);
      }
    } catch (e) {
      SetIsReady(false);
      setIsError(true);
    }
  };

  if (socket != null) {
    socket.on("connect", () => {
      console.log(socket.id);
      setSocketId(socket.id);
    });
  }

  useEffect(() => {
    getProblemData();
    const newSocket = io("https://backend.codeverd.com", {
      withCredentials: true,
    });
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    if (isReady) {
      window.localStorage.setItem(`code-${problemsData.problemId}`, code);
    }
  }, [code]);

  const runProgram = async () => {
    if (!!user == false) {
      if (!!user == false) {
        navigate("/login/Please login to submit code");
      }
    }

    SetShowTestCaseInput(false);
    setShowRunningTests(true);
    setIsLoading(true);
    SetShowCompilationSuccessText(false);
    setShowExpectedOutput(false);
    setShowYourOutput(false);
    SetIsAccepted(false);
    SetShowIsAcceptedText(false);
    SetIsFailed(false);
    SetShowIsFailedText(false);
    SetShowIsSegmentationFaultText(false);
    SetIsSegmentationFault(false);
    SetIsTimeout(false);
    SetShowTimeoutText(false);
    SetShowRuntimeErrorText(false);
    SetIsRuntimeError(false);
    SetIsCompilationError(false);
    SetShowCompilationErrorTest(false);
    SetShowCompilationError(false);
    SetCompilationError("");
    SetShowCustomOutput(false);
    SetCustomOutput("");

    if (runCompleteListenerAttached.current == false) {
      socket.on("run-complete", (data) => {
        console.log(data);
        runCompleteListenerAttached.current = true;
        SetShowCustomOutput(true);

        if (data.output.includes("segmentation_fault")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsSegmentationFault(true);
          SetShowIsSegmentationFaultText(true);
          SetShowCustomOutput(false);
        } else if (data.output.includes("timeout")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsTimeout(true);
          SetShowTimeoutText(true);
          SetShowCustomOutput(false);
        } else if (data.output.includes("compilation_error")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetShowCompilationErrorTest(true);
          SetIsCompilationError(true);
          SetCompilationError(data.output);
          SetShowCompilationError(true);
          SetShowCustomOutput(false);

          const scrollableElement =
            document.getElementsByClassName("right-pane")[0];
          const targetElement = document.getElementsByClassName(
            "compilationErrorClass"
          )[0];

          if (targetElement && scrollableElement) {
            const targetRect = targetElement.getBoundingClientRect();

            const targetOffsetTop =
              targetRect.top + scrollableElement.scrollTop;

            setTimeout(() => {
              const targetHeight = targetElement.offsetHeight;

              const scrollToPosition = targetOffsetTop + targetHeight;

              // Scroll to the desired position
              scrollableElement.scrollTop = scrollToPosition;
            }, 0);
          }
        } else if (data.output.includes("compilation_success")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetShowCompilationSuccessText(true);
          SetShowCustomOutput(false);
        } else if (data.output.trim() > "") {
          setIsLoading(false);
          setShowRunningTests(false);
          SetCustomOutput(data.output);

          const scrollableElement =
            document.getElementsByClassName("right-pane")[0];
          const targetElement =
            document.getElementsByClassName("CustomOutputClass")[0];

          if (targetElement && scrollableElement) {
            const targetRect = targetElement.getBoundingClientRect();

            const targetOffsetTop =
              targetRect.top + scrollableElement.scrollTop;

            setTimeout(() => {
              const targetHeight = targetElement.offsetHeight;

              const scrollToPosition = targetOffsetTop + targetHeight;

              // Scroll to the desired position
              scrollableElement.scrollTop = scrollToPosition;
            }, 0);
          }
        } else {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsRuntimeError(true);
          SetShowRuntimeErrorText(true);
        }
      });
    }
    const runObject = { code, customInput, socketId: socketId };

    try {
      const res = await axios.post(`/run/${problemsData.problemId}`, runObject);
      if (res.data.success == true) {
      } else {
        SetIsSubmitRunError(true);
        setShowRunningTests(false);
        setIsLoading(false);
      }
    } catch (e) {
      SetIsSubmitRunError(true);
      setShowRunningTests(false);
      setIsLoading(false);
    }
  };

  const getExpectedAndYourOutput = (string) => {
    let str = string;
    str = str.split("::");
    const exp = str[1].trimStart();
    const your = str[2].trimStart();
    const testcaseinput = str[3].trimStart();
    setShowExpectedOutput(true);
    setShowYourOutput(true);
    SetShowTestCaseInput(true);
    SetExpectedOutput(exp);
    SetYourOutput(your);
    SetTestCaseInput(testcaseinput);

    const scrollableElement = document.getElementsByClassName("right-pane")[0];

    setTimeout(() => {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }, 0);
  };

  const submitProblem = async () => {
    if (!!user == false) {
      navigate("/login/Please login to submit code");
    }

    SetShowTestCaseInput(false);
    SetShowCompilationSuccessText(false);
    SetShowCustomOutput(false);
    SetIsCustomInputChecked(false);
    SetCustomInput("");
    setIsLoading(true);
    setShowRunningTests(true);
    setShowExpectedOutput(false);
    setShowYourOutput(false);
    SetIsAccepted(false);
    SetShowIsAcceptedText(false);
    SetIsFailed(false);
    SetShowIsFailedText(false);
    SetShowIsSegmentationFaultText(false);
    SetIsSegmentationFault(false);
    SetIsTimeout(false);
    SetShowTimeoutText(false);
    SetShowRuntimeErrorText(false);
    SetIsRuntimeError(false);
    SetIsCompilationError(false);
    SetShowCompilationErrorTest(false);
    SetShowCompilationError(false);
    SetCompilationError("");
    if (submitCompleteListenerAttached.current == false) {
      socket.on("submit-complete", (data) => {
        console.log(data);
        submitCompleteListenerAttached.current = true;
        console.log(data);
        if (data.output.includes("passed")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsAccepted(true);
          SetShowIsAcceptedText(true);
        } else if (data.output.includes("failed")) {
          getExpectedAndYourOutput(data.output);
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsFailed(true);
          SetShowIsFailedText(true);
        } else if (data.output.includes("segmentation_fault")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsSegmentationFault(true);
          SetShowIsSegmentationFaultText(true);
        } else if (data.output.includes("timeout")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsTimeout(true);
          SetShowTimeoutText(true);
        } else if (data.output.includes("compilation_error")) {
          setIsLoading(false);
          setShowRunningTests(false);
          SetShowCompilationErrorTest(true);
          SetIsCompilationError(true);
          SetCompilationError(data.output);
          SetShowCompilationError(true);

          const scrollableElement =
            document.getElementsByClassName("right-pane")[0];
          const targetElement = document.getElementsByClassName(
            "compilationErrorClass"
          )[0];

          if (targetElement && scrollableElement) {
            const targetRect = targetElement.getBoundingClientRect();

            const targetOffsetTop =
              targetRect.top + scrollableElement.scrollTop;

            setTimeout(() => {
              const targetHeight = targetElement.offsetHeight;

              const scrollToPosition = targetOffsetTop + targetHeight;

              // Scroll to the desired position
              scrollableElement.scrollTop = scrollToPosition;
            }, 0);
          }
        } else {
          setIsLoading(false);
          setShowRunningTests(false);
          SetIsRuntimeError(true);
          SetShowRuntimeErrorText(true);
        }
      });
    }

    const submitObject = { code, socketId: socketId };
    try {
      const res = await axios.post(`/submit/${problemId}`, submitObject);
      if (res.data.success == true) {
      } else {
        SetIsSubmitRunError(true);
        setShowRunningTests(false);
        setIsLoading(false);
      }
    } catch (e) {
      SetIsSubmitRunError(true);
      setShowRunningTests(false);
      setIsLoading(false);
    }
  };

  const customInputCheckBoxClick = () => {
    const scrollableElement = document.getElementsByClassName("right-pane")[0];
    const targetElement =
      document.getElementsByClassName("customInputClass")[0];

    if (targetElement && scrollableElement) {
      const targetRect = targetElement.getBoundingClientRect();

      const targetOffsetTop = targetRect.top + scrollableElement.scrollTop;

      setTimeout(() => {
        const targetHeight = targetElement.offsetHeight;

        const scrollToPosition = targetOffsetTop + targetHeight;

        // Scroll to the desired position
        scrollableElement.scrollTop = scrollToPosition;
      }, 0);
    }

    if (isCustomInputChecked) {
      SetCustomOutput("");
      SetCustomInput("");
      SetShowCustomOutput(false);
    }

    SetIsCustomInputChecked(!isCustomInputChecked);
  };

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <BiMessageAltError size={40} color="red" />
        <div>Some error occurred.Please reload this page.</div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-white">
      <ReflexContainer orientation="vertical">
        <ReflexElement className="left-pane">
          {isReady ? (
            <div className="pane-content">
              <div className="my-6 mx-6">
                <>
                  <div className="text-3xl p-2">
                    {problemsData.problemTitle}
                  </div>
                  <div className="flex justify-between items-center mb-4">
                    <div className="p-2">
                      <DifficultyComponent
                        difficulty={problemsData.difficulty}
                      />
                    </div>
                    <div className="flex justify-center items-center border border-gray-400 rounded p-2">
                      <BiHistory size={25} />
                      <Link
                        to={`/submissions/${problemsData.problemId}`}
                        className=" font-semibold"
                      >
                        Submissions
                      </Link>
                    </div>
                    <div className="p-2">
                      <EditorialComponent link={problemsData.editorial} />
                    </div>
                  </div>
                  <div>
                    <CKEditor
                      editor={Editor}
                      data={problemsData.problemDescription}
                      config={{
                        toolbar: "None",
                      }}
                      disabled={true}
                    />
                  </div>
                  {/* {user.email == "admin@codeverd.com" && (
                <Link
                  to={`/editProblem/${problemsData.problemId}`}
                  className="text-blue-500 font-bold"
                >
                  Edit
                </Link>
              )} */}
                </>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full">
              <ScaleLoader color="#00A34A" size={500} />
            </div>
          )}
        </ReflexElement>

        <ReflexSplitter>
          <div></div>
          <div></div>
          <div></div>
        </ReflexSplitter>

        <ReflexElement className="right-pane" minSize="200" maxSize="1000">
          {isReady ? (
            <div ref={rightPaneRef}>
              <div className="pane-content">
                <div className="my-6 mx-2">
                  <div className="border my-1 inline-block">
                    <select>
                      <option>C++</option>
                    </select>
                  </div>
                  <div className="border rounded-lg p-1">
                    <CodeMirror
                      value={code}
                      height="600px"
                      theme={eclipseInit({
                        settings: {
                          caret: "#000",
                          fontFamily: "monospace",
                        },
                      })}
                      extensions={langs.cpp()}
                      onChange={(value, change) => {
                        setCode(value);
                      }}
                    />
                  </div>

                  <div className="flex justify-between">
                    <div className="flex justify-center items-center">
                      <div className="sweet-loading p-1">
                        <RingLoader
                          loading={isLoading}
                          cssOverride={override}
                          size={40}
                          color="#00A34A"
                        />

                        {isCompilationError && (
                          <BiError size={40} color="gray" />
                        )}

                        {isAccepted && (
                          <BsCheckCircleFill size={40} color="#00A34A" />
                        )}

                        {showCompilationSuccessText && (
                          <BsCheckCircleFill size={40} color="#00A34A" />
                        )}

                        {isFailed && <BsXCircleFill size={40} color="red" />}

                        {isSegmentationFault && (
                          <RiErrorWarningFill size={40} color="#33BBFF" />
                        )}

                        {isTimeout && <BiTime size={40} color="#FFD100" />}

                        {isRuntimeError && (
                          <AiFillQuestionCircle size={40} color="#fff" />
                        )}
                      </div>

                      <div className="text-center">
                        {showRunningTests && (
                          <div className="font-light">Running Tests</div>
                        )}
                        {showIsAcceptedText && (
                          <div className="font-light">Accepted!</div>
                        )}
                        {showIsFailedText && (
                          <div className=" font-light">Some tests failed</div>
                        )}
                        {showIsSegmentationFaultText && (
                          <div className=" font-light">Segmentation Fault</div>
                        )}
                        {isTimeout && (
                          <div className=" font-light">Time Limit Exceeded</div>
                        )}

                        {isRuntimeError && (
                          <div className=" font-light">
                            Some Error. Try again.
                          </div>
                        )}

                        {isCompilationError && (
                          <div className=" font-light">Compilation Error</div>
                        )}

                        {showCompilationSuccessText && (
                          <div className=" font-light">Compilation Success</div>
                        )}

                        {isSubmitRunError && (
                          <div className=" font-light">
                            Some Error Occurred. Try again.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="py-4 flex justify-end font-mono customInputClass">
                      <div className="flex justify-end items-center">
                        <input
                          type="checkbox"
                          ref={userInputRef}
                          checked={isCustomInputChecked}
                          onChange={customInputCheckBoxClick}
                        />
                        <div className="font-bold p-1">Custom Input</div>
                      </div>
                      <button
                        disabled={isLoading}
                        className={`p-2 ml-4 rounded text-white w-[80px]  ${
                          isLoading
                            ? "hover:cursor-not-allowed bg-gray-400"
                            : "bg-gray-700 hover:bg-gray-600"
                        }`}
                        onClick={runProgram}
                      >
                        Run
                      </button>
                      <button
                        disabled={isLoading}
                        className={`bg-green-700 p-2 ml-4 rounded text-white w-[150px]  ${
                          isLoading
                            ? "hover:cursor-not-allowed bg-green-500"
                            : "bg-green-700 hover:bg-green-600"
                        }`}
                        onClick={submitProblem}
                      >
                        Submit code
                      </button>
                    </div>
                  </div>
                  <div>
                    {isCustomInputChecked && (
                      <div>
                        <textarea
                          id="Custom Input"
                          rows="4"
                          className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white font-mono border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Custom input here..."
                          onChange={(e) => {
                            SetCustomInput(e.target.value);
                          }}
                          value={customInput}
                        ></textarea>
                      </div>
                    )}
                    <div className="compilationErrorClass">
                      {showCompilationError && (
                        <div
                          className="border border-gray overflow-scroll shadow-md text-red-400"
                          ref={compilationErrorRef}
                        >
                          <pre>
                            <div className="p-4">
                              {compilationError.replace(
                                "compilation_error",
                                ""
                              )}
                            </div>
                          </pre>
                        </div>
                      )}
                    </div>
                    <div className="CustomOutputClass">
                      {isCustomInputChecked && showCustomOutput && (
                        <div>
                          <label
                            htmlFor="Custom Input"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Output
                          </label>
                          <textarea
                            id="Output"
                            rows="4"
                            className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white font-mono border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            value={customOutput}
                          ></textarea>
                        </div>
                      )}
                    </div>

                    <div>
                      {showTestCaseInput && (
                        <div className="mt-2">
                          <label
                            htmlFor="TestCase Input"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            <div className="flex gap-1">
                              TestCase Input{" "}
                              <div
                                onClick={() => SetShowTestCase(!showTestCase)}
                              >
                                {showTestCase ? (
                                  <AiFillEye size={20} />
                                ) : (
                                  <AiFillEyeInvisible size={20} />
                                )}
                              </div>
                            </div>
                          </label>
                          <textarea
                            id="TestCase Input"
                            rows="4"
                            className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white font-mono border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            disabled
                            value={
                              showTestCase
                                ? TestCaseInput
                                : "Tap on reveal icon above to reveal the testcase info"
                            }
                          ></textarea>
                        </div>
                      )}

                      {showExpectedOutput && (
                        <div className="mt-2">
                          <label
                            htmlFor="Expected Output"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Excepted Output
                          </label>
                          <textarea
                            id="Expected Output"
                            rows="4"
                            className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white font-mono border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            disabled
                            value={
                              showTestCase
                                ? ExpectedOutput
                                : "Tap on reveal icon above to reveal the testcase info"
                            }
                          ></textarea>
                        </div>
                      )}
                      {showYourOutput && (
                        <div className="mt-2">
                          <label
                            htmlFor="Your Output"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Your Output
                          </label>
                          <textarea
                            id="Your Output"
                            rows="4"
                            className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white font-mono border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            disabled
                            value={
                              showTestCase
                                ? YourOutput
                                : "Tap on reveal icon above to reveal the testcase info"
                            }
                          ></textarea>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full">
              <HashLoader />
            </div>
          )}
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}
export default CodingPlayground;

import Card from "../components/Card";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useContext, useEffect, useState } from "react";
import arraysGIF from "../Assets/topicgifs/arrays.gif";
import searchingGIF from "../Assets/topicgifs/searching.gif";
import twoPointersGIF from "../Assets/topicgifs/TwoPointers.gif";
import linkedListsGIF from "../Assets/topicgifs/LinkedLists.gif";
import stacksAndQueuesGIF from "../Assets/topicgifs/StacksAndQueues.gif";
import backtrackingGIF from "../Assets/topicgifs/BackTracking.gif";
import binaryTreesGIF from "../Assets/topicgifs/BinaryTrees.gif";
import bstAndHeapGIF from "../Assets/topicgifs/BSTandHeap.gif";
import MathsAndBitManipulationGIF from "../Assets/topicgifs/MathsAndBitManipulation.gif";
import DynamicProgrammingGIF from "../Assets/topicgifs/DynamicProgramming.gif";
import GreedyAlgorithmsGIF from "../Assets/topicgifs/GreedyAlgorithms.gif";
import GraphsGIF from "../Assets/topicgifs/Graphs.gif";
import StringsAndTriesGIF from "../Assets/topicgifs/StringsAndTries.gif";
import HashLoader from "react-spinners/HashLoader";
import { BiMessageAltError } from "react-icons/bi";
import { FaRandom } from "react-icons/fa";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";

function PractisePage() {
  const [topicList, setTopicList] = useState([]);
  const [problemsData, SetProblemsData] = useState([]);
  const [userSolvedProblems, SetUserSolvedProblems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, SetIsError] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const getTopicData = async () => {
    try {
      const res = await axios.get("/topics");
      setTopicList(res.data.topic_data);
    } catch (err) {
      SetIsError(true);
      setIsLoading(false);
    }
  };

  const getProblemsData = async () => {
    try {
      const res = await axios.get(`/api/problems/`);
      if (res.data.success == true) {
        SetProblemsData(res.data.problems);
      } else {
        SetIsError(true);
        setIsLoading(false);
      }
    } catch (err) {
      SetIsError(true);
      setIsLoading(false);
    }
  };

  const getUserSolvedProblems = async () => {
    try {
      const res = await axios.get(`/api/usersolvedproblems/`);

      if (res.data.success == true) {
        SetUserSolvedProblems(res.data.usersolvedproblems);
        setIsLoading(false);
      } else if (!!user && res.data.success == false) {
        SetIsError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      SetIsError(true);
      setIsLoading(false);
    }
  };

  const calculateProgress = (topicId) => {
    let totalSolved = 0;
    let totalProblems = 0;

    for (let i = 0; i < problemsData.length; i++) {
      if (problemsData[i].topics.includes(topicId)) {
        totalProblems = totalProblems + 1;
      }
    }

    for (let i = 0; i < problemsData.length; i++) {
      for (let j = 0; j < userSolvedProblems.length; j++) {
        if (userSolvedProblems[j] == problemsData[i].problemId) {
          totalSolved++;
        }
      }
    }

    return (totalSolved / totalProblems) * 100;
  };

  // useEffect(() => {
  //   // Check if both problemsData and userSolvedProblems have data
  //   if (problemsData.length > 0 && userSolvedProblems.length > 0) {
  //     // Perform the progress calculation
  //     calculateProgress(topicList.id);
  //   }
  // }, [problemsData, userSolvedProblems]);

  useEffect(() => {
    getTopicData();
    getProblemsData();
    getUserSolvedProblems();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <HashLoader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <BiMessageAltError size={40} color="red" />
        <div>Some error occurred.Please reload this page.</div>
      </div>
    );
  }

  const generateRandomProblem = () => {
    const random = Math.floor(Math.random() * (problemsData.length - 1));

    navigate(`/problem/${problemsData[random].problemId}`);
  };

  return (
    <div className="w-full bg-white p-4 text-white">
      <div className="max-w-[1024px] m-auto">
        <div className=" cursor-pointer">
          <div class="p-4 w-[200px] text-center rounded-full mt-3  mb-3 mx-auto text-white bg-[#34CCCC]">
            <div
              className="flex items-center justify-center gap-2"
              onClick={generateRandomProblem}
            >
              <div>Random Problem</div> <FaRandom size={20} />
            </div>
          </div>
        </div>
        {/* <div className="block md:grid md:grid-cols-2 lg:grid-cols-3 p-4 gap-3 align-center">
          <div className="w-[300px] my-2 mx-auto">
            <Card
              color={"bg-gradient-to-b from-teal-700 to-cyan-500"}
              title={"Total Problems"}
              subtitle={"150"}
            />
          </div>
          <div className="w-[300px] my-2 mx-auto">
            <Card
              color={"bg-gradient-to-b from-sky-700 to-indigo-500"}
              title={"Total Solved"}
              subtitle={0}
            />
          </div>
          <div className="w-[300px] my-2 mx-auto">
            <Card
              color={"bg-gradient-to-b from-teal-700 to-cyan-500"}
              title={"Total Remaining"}
              subtitle={"150"}
            />
          </div>
        </div> */}

        <div className="grid text-black md:grid-cols-3 sm:grid-cols-2 gap-4 topic-grid">
          {topicList.map((topic, index) => {
            return (
              <Link
                to={`/practise/${topic.id}`}
                className="border text-2xl  flex justify-between items-center hover:scale-105 duration-150 rounded-lg h-[400px] flex-col relative"
                key={index}
              >
                <div className="h-1/2 w-full flex justify-center items-center">
                  {topic.id == "ArraysAndHashing" && (
                    <img src={arraysGIF} width={100} />
                  )}
                  {topic.id == "Searching" && (
                    <img src={searchingGIF} width={100} />
                  )}
                  {topic.id == "TwoPointers" && (
                    <img src={twoPointersGIF} width={100} />
                  )}
                  {topic.id == "LinkedLists" && (
                    <img src={linkedListsGIF} width={100} />
                  )}
                  {topic.id == "StacksAndQueues" && (
                    <img src={stacksAndQueuesGIF} width={100} />
                  )}
                  {topic.id == "Backtracking" && (
                    <img src={backtrackingGIF} width={100} />
                  )}
                  {topic.id == "BinaryTrees" && (
                    <img src={binaryTreesGIF} width={100} />
                  )}
                  {topic.id == "BSTandHeap" && (
                    <img src={binaryTreesGIF} width={100} />
                  )}
                  {topic.id == "MathsAndBitManipulation" && (
                    <img src={MathsAndBitManipulationGIF} width={100} />
                  )}
                  {topic.id == "DynamicProgramming" && (
                    <img src={DynamicProgrammingGIF} width={100} />
                  )}
                  {topic.id == "GreedyAlgorithms" && (
                    <img src={GreedyAlgorithmsGIF} width={100} />
                  )}
                  {topic.id == "Graphs" && <img src={GraphsGIF} width={100} />}
                  {topic.id == "StringsAndTries" && (
                    <img src={StringsAndTriesGIF} width={100} />
                  )}
                </div>
                <div className="h-1/2 bg-[#34CCCC] w-full flex justify-center items-center text-white text-center">
                  {topic.topic}
                </div>
                <div
                  style={{ width: 75, height: 75 }}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <div className=" bg-white p-1 rounded-full  text-lg 	">
                    <CircularProgressbar
                      value={Math.round(calculateProgress(topic.id))}
                      text={`${Math.round(calculateProgress(topic.id))}%`}
                      styles={buildStyles({
                        pathColor: "#34CCCC",
                        textColor: "#34CCCC",
                        trailColor: "#D1D5DB",
                        backgroundColor: "#FFFFFF",
                        textSize: "25px",
                      })}
                      strokeWidth={4}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default PractisePage;

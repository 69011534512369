import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import SetProblemError from "../components/SetProblemError";
import SetProblemSuccess from "../components/SetProblemSuccess";

import { useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { Navigate } from "react-router-dom";

function SetProblemPage() {
  const [problemTitle, setProblemTitle] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [difficulty, setDifficulty] = useState("easy");
  const [isArraysAndHashingChecked, SetIsArraysAndHashingChecked] =
    useState(false);
  const [isSearchingAndSortingChecked, SetIsSearchingAndSortingChecked] =
    useState(false);
  const [isTwoPointersChecked, SetIsTwoPointersChecked] = useState(false);
  const [isLinkedListsChecked, SetIsLinkedListsChecked] = useState(false);
  const [isStacksAndQueuesChecked, SetIsStacksAndQueuesChecked] =
    useState(false);
  const [isBacktrackingChecked, SetIsBacktrackingChecked] = useState(false);
  const [isBinaryTreesChecked, SetIsBinaryTreesChecked] = useState(false);
  const [isBSTandHeapChecked, SetIsBSTandHeapChecked] = useState(false);
  const [
    isMathsAndBitManipulationChecked,
    SetIsMathsAndBitManipulationChecked,
  ] = useState(false);
  const [isDynamicProgrammingChecked, SetIsDynamicProgrammingChecked] =
    useState(false);
  const [isGraphsChecked, SetIsGraphsChecked] = useState(false);
  const [isStringsAndTriesChecked, SetIsStringsAndTriesChecked] =
    useState(false);
  const [isGreedyAlgorithmsChecked, SetIsGreedyAlgorithmsChecked] =
    useState(false);

  const [companyTags, SetCompanyTags] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [errmsg, Seterrmsg] = useState("UnAuthorized");
  const [iserror, Setiserror] = useState(false);
  const [isSuccess, SetisSuccess] = useState(false);

  //usercontext
  const { user, ready } = useContext(UserContext);

  // //companies
  // const [isAdobeChecked,SetIsAdobeChecked] = useState(false);
  // const [isAmazonChecked, SetIsAmazonChecked] = useState(false);
  // const [isAmdocsChecked, SetIsAmdocsChecked] = useState(false);
  // const [isAppleChecked, SetIsAppleChecked] = useState(false);
  // const [isBarclaysChecked, SetIsBarclaysChecked] = useState(false);
  // const [isCiscoChecked, SetIsCiscoChecked] = useState(false);
  // const [isCitrixChecked, SetIsCitrixChecked] = useState(false);
  // const [isCodeNationChecked, SetIsCodeNationChecked] = useState(false);
  // const [isDEShawChecked, SetIsDEShawChecked] = useState(false);
  // const [isDellChecked, SetIsDellChecked] = useState(false);
  // const [isDirectiChecked, SetIsDirectiChecked] = useState(false);
  // const [isDunzoChecked, SetIsDunzoChecked] = useState(false);
  // const [isEbayChecked, SetIsEbayChecked] = useState(false);
  // const [isExpediaChecked, SetIsExpediaChecked] = useState(false);
  // const [isFacebookChecked, SetIsFacebookChecked] = useState(false);
  // const [isFlipkartChecked, SetIsFlipkartChecked] = useState(false);
  // const [isGoldmannSachsChecked, SetIsGoldmannSachsChecked] = useState(false);
  // const [isGoogleChecked, SetIsGoogleChecked] = useState(false);
  // const [isHSBCChecked, SetIsHSBCChecked] = useState(false);
  // const [isIntuitChecked, SetIsIntuitChecked] = useState(false);
  // const [isLinkedInChecked, SetIsLinkedInChecked] = useState(false);
  // const [isMakeMyTripChecked, SetIsMakeMyTripChecked] = useState(false);
  // const [isMicrosoftInChecked, SetIsMicrosoftInChecked] = useState(false);
  // const [isMorganStanleyChecked, SetIsMorganStanleyChecked] = useState(false);
  // const [isMyntraChecked, SetIsMyntraChecked] = useState(false);
  // const [isOlaChecked, SetIsOlaChecked] = useState(false);
  // const [isOracleChecked, SetIsOracleChecked] = useState(false);
  // const [isOyoChecked, SetIsOyoChecked] = useState(false);
  // const [isPaypalChecked, SetIsPaypalChecked] = useState(false);
  // const [isPaytmChecked, SetIsPaytmChecked] = useState(false);
  // const [isPayUChecked, SetIsPayUChecked] = useState(false);
  // const [isQualcommChecked, SetIsQualcommChecked] = useState(false);
  // const [isSamsungChecked, SetIsSamsungChecked] = useState(false);
  // const [isSnapdealChecked, SetIsSnapdealChecked] = useState(false);
  // const [isTwitterChecked, SetIsTwitterChecked] = useState(false);
  // const [isUberChecked, SetIsUberChecked] = useState(false);
  // const [isVisaChecked, SetIsVisaChecked] = useState(false);
  // const [isVMwareChecked, SetIsVMwareChecked] = useState(false);
  // const [isYahooChecked, SetIsYahooChecked] = useState(false);
  // const [isZohoChecked, SetIsZohoChecked] = useState(false);

  const submitProblem = async (e) => {
    e.preventDefault();

    const res = await axios.post("/setProblem", {
      problemId: problemTitle.split(" ").join(""),
      problemTitle,
      difficulty,
      problemDescription,
      topics: [
        isArraysAndHashingChecked ? "ArraysAndHashing" : "",
        isSearchingAndSortingChecked ? "Searching" : "",
        isTwoPointersChecked ? "TwoPointers" : "",
        isLinkedListsChecked ? "LinkedLists" : "",
        isStacksAndQueuesChecked ? "StacksAndQueues" : "",
        isBacktrackingChecked ? "Backtracking" : "",
        isBinaryTreesChecked ? "BinaryTrees" : "",
        isBSTandHeapChecked ? "BSTandHeap" : "",
        isMathsAndBitManipulationChecked ? "MathsAndBitManipulation" : "",
        isDynamicProgrammingChecked ? "DynamicProgramming" : "",
        isGraphsChecked ? "Graphs" : "",
        isStringsAndTriesChecked ? "StringsAndTries" : "",
        isGreedyAlgorithmsChecked ? "GreedyAlgorithms" : "",
      ].filter((n) => n),
      companyTags: companyTags.split(","),
      timeLimit,
    });

    if (res.data.success == false) {
      Seterrmsg(res.data.error);
      Setiserror(true);
      SetisSuccess(false);
    } else {
      Seterrmsg(res.data.error);
      Setiserror(false);
      SetisSuccess(true);
    }
  };

  if (ready && !user) {
    return <Navigate to="/" />;
  }

  if (ready && user) {
    if (user.email !== "admin@codeverd.com") {
      return <Navigate to="/" />;
    }
  }

  return (
    <div className="w-full p-4 bg-white">
      <form className="max-w-[1024px] m-auto p-4" onSubmit={submitProblem}>
        {iserror && <SetProblemError error={errmsg} />}
        {isSuccess && <SetProblemSuccess error={errmsg} />}
        <input
          type="text"
          placeholder="Problem Title"
          className="border p-2 my-4 w-full border-black"
          onChange={(e) => {
            setProblemTitle(e.target.value);
          }}
          value={problemTitle}
        />

        <select
          name="difficulty"
          className="border p-2 my-4 border-black"
          onChange={(e) => {
            setDifficulty(e.target.value);
          }}
          value={difficulty}
        >
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>

        <div className="my-4">
          <input
            type="checkbox"
            id="ArraysAndHashing"
            name="Topics"
            value="ArraysAndHashing"
            onChange={(e) => {
              SetIsArraysAndHashingChecked(!isArraysAndHashingChecked);
            }}
          />
          <label htmlFor="ArraysAndHashing"> Arrays And Hashing</label>
          <br />
          <input
            type="checkbox"
            id="SearchingAndSorting"
            name="Topics"
            onChange={(e) => {
              SetIsSearchingAndSortingChecked(!isSearchingAndSortingChecked);
            }}
            value="SearchingAndSorting"
          />

          <label htmlFor="SearchingAndSorting"> Searching</label>
          <br />
          <input
            type="checkbox"
            id="TwoPointers"
            name="Topics"
            value="TwoPointers"
            onChange={(e) => {
              SetIsTwoPointersChecked(!isTwoPointersChecked);
            }}
          />
          <label htmlFor="TwoPointers"> Two Pointers</label>
          <br />
          <input
            type="checkbox"
            id="LinkedLists"
            name="Topics"
            value="LinkedLists"
            onChange={(e) => {
              SetIsLinkedListsChecked(!isLinkedListsChecked);
            }}
          />
          <label htmlFor="LinkedLists"> Linked Lists</label>
          <br />
          <input
            type="checkbox"
            id="StacksAndQueues"
            name="Topics"
            value="StacksAndQueues"
            onChange={(e) => {
              SetIsStacksAndQueuesChecked(!isStacksAndQueuesChecked);
            }}
          />
          <label htmlFor="StacksAndQueues"> Stacks and Queues</label>

          <br />

          <input
            type="checkbox"
            id="Backtracking"
            name="Topics"
            value="Backtracking"
            onChange={(e) => {
              SetIsBacktrackingChecked(!isBacktrackingChecked);
            }}
          />
          <label htmlFor="Backtracking"> Backtracking</label>

          <br />
          <input
            type="checkbox"
            id="BinaryTrees"
            name="Topics"
            value="BinaryTrees"
            onChange={(e) => {
              SetIsBinaryTreesChecked(!isBinaryTreesChecked);
            }}
          />
          <label htmlFor="BinaryTrees"> Binary Trees</label>
          <br />

          <input
            type="checkbox"
            id="BSTandHeap"
            name="Topics"
            value="BSTandHeap"
            onChange={(e) => {
              SetIsBSTandHeapChecked(!isBSTandHeapChecked);
            }}
          />
          <label htmlFor="BSTandHeap"> BST and Heap</label>

          <br />
          <input
            type="checkbox"
            id="MathsAndBitManipulation"
            name="Topics"
            value="MathsAndBitManipulation"
            onChange={(e) => {
              SetIsMathsAndBitManipulationChecked(
                !isMathsAndBitManipulationChecked
              );
            }}
          />
          <label htmlFor="MathsAndBitManipulation">
            {" "}
            Maths And Bit Manipulation
          </label>
          <br />

          <input
            type="checkbox"
            id="DynamicProgramming"
            name="Topics"
            value="DynamicProgramming"
            onChange={(e) => {
              SetIsDynamicProgrammingChecked(!isDynamicProgrammingChecked);
            }}
          />
          <label htmlFor="DynamicProgramming"> Dynamic Programming</label>

          <br />
          <input
            type="checkbox"
            id="GreedyAlgorithms"
            name="Topics"
            value="GreedyAlgorithms"
            onChange={(e) => {
              SetIsGreedyAlgorithmsChecked(!isGreedyAlgorithmsChecked);
            }}
          />
          <label htmlFor="GreedyAlgorithms"> Greedy Algorithms</label>
          <br />

          <input
            type="checkbox"
            id="Graphs"
            name="Topics"
            value="Graphs"
            onChange={(e) => {
              SetIsGraphsChecked(!isGraphsChecked);
            }}
          />
          <label htmlFor="Graphs"> Graphs</label>

          <br />
          <input
            type="checkbox"
            id="StringsAndTries"
            name="Topics"
            value="StringsAndTries"
            onChange={(e) => {
              SetIsStringsAndTriesChecked(!isStringsAndTriesChecked);
            }}
          />
          <label htmlFor="StringsAndTries"> Strings And Tries</label>
          <br />
        </div>

        <div className="my-6">
          <CKEditor
            editor={Editor}
            className="p-2 my-2"
            data="Write the problem here."
            onChange={(event, editor) => {
              setProblemDescription(editor.getData());
            }}
            // config={{
            //   toolbar: "None",
            // }}
            // disabled={true}
          />
        </div>

        <div className="my-4 w-full">
          <input
            type="text"
            placeholder="Comma seperated Company Names"
            className="border border-black p-4 w-full"
            onChange={(e) => {
              SetCompanyTags(e.target.value);
            }}
            value={companyTags}
          />
        </div>

        <div className="my-4 w-full">
          <input
            type="text"
            placeholder="Time Limit in Seconds"
            className="border border-black p-4 w-full"
            onChange={(e) => {
              setTimeLimit(e.target.value);
            }}
            value={timeLimit}
          />
        </div>

        <button type="submit" className="border p-2">
          Submit
        </button>
      </form>
    </div>
  );
}
export default SetProblemPage;

function Card({ color, title, subtitle }) {
  return (
    <div
      className={`block p-6 ${color} border border-gray-200 rounded-lg shadow`}
    >
      <p>
        <h5 className=" text-3xl  font-thin tracking-tight text-white-900 dark:text-white text-center">
          {title}
        </h5>
      </p>
      <h1 className="text-4xl font-sans text-center">{subtitle}</h1>
    </div>
  );
}
export default Card;

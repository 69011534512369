import axios from "axios";
import { useState } from "react";
import LoginRegisterError from "../components/LoginRegisterError";
import LoginRegisterSuccess from "../components/LoginRegisterSuccess";

function ReportProblem() {
  const [name, SetName] = useState("");
  const [email, setEmail] = useState("");
  const [problem, setProblem] = useState("");
  const [error, SetError] = useState(false);
  const [success, SetSuccess] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();

    const body = { name: name, email: email, problem: problem };

    try {
      const result = await axios.post("/reportProblem", body);

      if (result.data.success == true) {
        SetSuccess(true);
      } else {
        SetError(true);
      }
    } catch (e) {
      SetError(true);
    }
  };

  return (
    <div className="w-full bg-white">
      <div className="text-center  font-sans p-4 text-4xl">
        Report a Problem
      </div>
      <div className="sm:w-[400px] md:w-[800px] mx-auto mb-4">
        {error && <LoginRegisterError error="Some error, please try again" />}
        {success && (
          <LoginRegisterSuccess message="We will get back to you on this." />
        )}
      </div>
      <div className="h-[500px] flex justify-center items-start">
        <form
          onSubmit={submitForm}
          className="flex flex-col items-center justify-center"
        >
          <div className="flex items-center justify-center mb-3 ">
            <input
              type="text"
              placeholder="Name"
              className=" border p-2 rounded-md sm:w-[400px] md:w-[800px]"
              value={name}
              onChange={(e) => SetName(e.target.value)}
            ></input>
          </div>
          <div className="flex items-center justify-center mb-3 ">
            <input
              type="email"
              placeholder="Email"
              className=" border p-2 rounded-md sm:w-[400px] md:w-[800px]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className="flex items-center justify-center mb-3 ">
            <input
              type="text"
              placeholder="Please write the problem you are facing...."
              className=" border p-2 sm:w-[400px] md:w-[800px] rounded-md h-[100px]"
              value={problem}
              onChange={(e) => {
                setProblem(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex items-center justify-center ">
            <button type="submit" className="p-2 border rounded-md">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ReportProblem;

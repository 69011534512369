import login from "../Assets/images/login.jpg";
import LoginRegisterError from "../components/LoginRegisterError";
import LoginRegisterSuccess from "../components/LoginRegisterSuccess";
import { Link, Navigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [navigate, setNavigate] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { error } = useParams();

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post("/login", {
        email,
        password,
      });
      if (data.success == false) {
        setIsError(true);
        setErrorMsg(data.error);
      } else {
        setIsError(false);
        setSuccessMsg("Logging you in..");
        setUser(data.userInfo);
        setIsSuccess(true);
        setNavigate(true);
      }
    } catch (e) {
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  if (!!user || navigate) {
    return <Navigate to="/account" />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 bg-white h-screen w-full">
      <div className="hidden md:block">
        <img className="w-full h-screen object-cover" src={login} alt="/" />
      </div>

      <div className="flex flex-col justify-center">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-4 border"
          onSubmit={loginUser}
        >
          <div>{!!error && <LoginRegisterError error={error} />}</div>
          {isError && <LoginRegisterError error={errorMsg} />}
          {isSuccess && <LoginRegisterSuccess message={successMsg} />}
          <h2 className="text-4xl font-bold text-center py-6">Login</h2>
          <div className="flex flex-col py-2">
            <input
              className="border p-2"
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col py-2">
            <input
              className="border p-2"
              type="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <button
            className="border w-full my-5 py-2 bg-sky-600 text-white"
            type="submit"
          >
            Sign In
          </button>
          <div className="flex justify-center">
            <p className="text-gray-500">
              Dont have an account?{" "}
              <Link className="underline text-black" to="/register">
                Sign up
              </Link>
            </p>
          </div>
          <div className="flex justify-center p-3">
            <Link className="underline text-black" to="/ResetPassword">
              Forgot Password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
export default LoginPage;

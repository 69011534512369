function DifficultyComponent({ difficulty }) {
  if (difficulty == "easy") {
    return (
      <div>
        <p className="font-bold text-green-600 capitalize">{difficulty}</p>
      </div>
    );
  }

  if (difficulty == "medium") {
    return (
      <div>
        <p className="font-bold text-yellow-800 capitalize">{difficulty}</p>
      </div>
    );
  }

  if (difficulty == "difficult") {
    return (
      <div>
        <p className="font-bold text-red-400 capitalize">{difficulty}</p>
      </div>
    );
  }
}
export default DifficultyComponent;
